<template>
  <div class="header-margin row">
    <div class="col-12 col-md-9">
      <q-item>
        <q-item-section avatar>
          <q-avatar class="avatar">
            <img
              :src="ecommData.partner_photo"
              :alt="ecommData.highlight_text"
            />
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label class="influ-header-inner">
            <div class="influ-name" v-text="ecommData.highlight_text" />
          </q-item-label>
          <q-item-label caption class="q-mt-lg description" v-text="ecommData.highlight_description" />
          <InfluSocialNetworks 
            class="q-mt-sm" 
            v-if="hasSocialNetworks?.length" 
            :socialNetworks="hasSocialNetworks"
            :isBigScreen="$q.screen.gt.sm" 
          />
        </q-item-section>
      </q-item>
    </div>
    <div class="col-12 col-md-3 text-center">
      <div class="action-buttons" style="display: flex">
        <div
          v-if="
            ecommCustomization?.voucher
          "
        >
          <span>
            <div v-html="voucherDescription" />
            <a 
              v-if="ecommCustomization?.voucher?.discount == 0" 
              role="button"
              @click="seeMoreCouponDescription()"
            >
              Ver Mais
            </a>
          </span>
          <q-btn
            outline
            :label="ecommCustomization.voucher.number"
            class="q-mt-xs full-width btn-voucher"
            @click="copyLink(ecommCustomization.voucher.number)"
          >
              <q-tooltip v-if="$q.screen.gt.sm">
                Copiar cupom de desconto
              </q-tooltip>
              <q-icon class="q-ml-sm" name="content_copy" size="1.3rem" />
          </q-btn>
        </div>
        <div v-else v-html="$t('landing_page.shareWithFriends')" />
        <q-btn
          flat
          class="q-mt-xs full-width btn-share"
          @click="share"
        >
          <q-tooltip v-if="$q.screen.gt.sm">
            Compartilhar loja
          </q-tooltip>
          <q-icon name="share" size="1rem" />
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { getCustomLayoutLP } from "@/shared/helpers/customLayoutHelper";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { defineProps, defineAsyncComponent, computed } from "vue";
import { useQuasar } from 'quasar';

const InfluSocialNetworks = defineAsyncComponent(() => import('./InfluSocialNetworks.vue'));

const props = defineProps({
  ecommData: {
    type: Object,
    required: true,
  },
  ecommCustomization: {
    type: Object,
    required: true,
  },
});

const $q = useQuasar();

const share = () => {
  navigator.share({
    url: `${window.location.href}`,
  });
};

const hasSocialNetworks = computed(() => {
  const socialNetworks = props.ecommData.social_networks;

  return socialNetworks &&
  socialNetworks.filter(social => 
    social.network !== 'link_to_store'
  )
})

const voucherDescription = computed(() => {
  const voucher = props.ecommCustomization?.voucher;

  if ( !voucher ) return false;

  const typeDiscount = voucher.type_discount

  if ( typeDiscount === "percent" && voucher.discount > 0 ) {
    return `
      Cupom de desconto <b>${voucher.discount}% OFF</b> 
    `
  }

  return `
    ${voucher?.description}
  `
})

const seeMoreCouponDescription = () => {
  $q.dialog({
    title: `Cupom ${props.ecommCustomization?.voucher?.number}`,
    message: props.ecommCustomization?.voucher?.description
  })
}

function copyLink(link) {
  if (!link) {
    notifyError("Nenhum link cupom para cópia.");
    return;
  }

  navigator.clipboard
    .writeText(link)
    .then(() => notifySuccess("Cupom copiado com sucesso!"))
    .catch(() => notifyError("Erro ao copiar o cupom."));
}


</script>

<style lang="scss" scoped>
.avatar {
  width: 60px;
  height: 60px;
}

.avatar img {
  border: 3px solid var(--primary-custom-color);
}

.header-margin {
  background: #f4f4f4;
  display: flex;
  align-items: center;
  padding: 0.5rem 35px;
}

.header-margin > div:last-child {
  margin-top: 7px
}

.q-item {
  padding: 8px 0px;
}

.influ-header-inner > div {
  margin-top: 5px
}

.influ-name {
  font-size: clamp(14px, 4vw, 20px);
  font-weight: 500 !important;
}

.description {
  margin-top: 0.5rem;
  font-size: clamp(11px, 3.5, 14px);
  display: none;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action-buttons > div:first-child {
  font-size: 12px;
  display: grid;
  width: 250px;
  max-width: 250px;
}

.action-buttons > div:first-child span:first-child {
  display: flex;
  white-space: nowrap;
  max-width: 250px;
}

.action-buttons > div:first-child span:first-child div {
  white-space: nowrap;
  max-width: 220px;
  margin: 0 auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

.action-buttons > div:first-child span:first-child a {
  color: var(--primary-custom-color);
  text-decoration: underline;
  cursor: pointer
}

.btn-voucher :deep(.q-btn__content span) {
  font-size: 12px
}

.btn-voucher::before {
  border: 1px dashed;
}

.btn-share {
  background-color: var(--primary-custom-color);
  color: #fff;
  align-self: end;
  width: fit-content !important;
}

.btn-share:hover {
  opacity: 0.85
}

@media ( max-width: 639px ) {
  .action-buttons {
    justify-content: center;
  }

  .description {
    line-height: 1.3;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    height: calc(2* 1.35em);
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 2rem;
  }
}

@media screen and (min-width: 640px) {
  .avatar {
    width: 100px;
    height: 100px;
  }

  .header-margin {
    padding: 0.5rem 60px;
  }
}
</style>
