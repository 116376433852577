<template>
  <div v-if="!in_request" class="public-store">
    <div class="container" v-if="Object.keys(getCompanieDetails).length">
      <Header 
        :companyDetails="getCompanieDetails" 
        :getProductsByCategories="getProductsByCategories"
        :ecommCustomization="getInfluEcomCustomization"
        :scrollTo="scrollTo"
      />
      <InfluHeader
        :ecommData="getInfluData"
        :ecommCustomization="getInfluEcomCustomization"
        v-if="Object.keys(getInfluData).length"
      />
    </div>

    <template v-if="Object.keys(getProductsByCategories).length">
      <div class="container-categorys">
        <div class="section-category" v-for="(prodsByCategories, index) in getProductsByCategories" :key="index">
          <div
            :id="prodsByCategories.title"
            class="text-black text-weight-thin text-center category-title"
            v-html="prodsByCategories.title"
            :class="{
              'q-pa-md text-h4': $q.screen.width > 900,
              'text-h5': $q.screen.width < 900,
            }"
          />
          <div>
            <Products 
              :products="prodsByCategories.prods_parent" 
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="bg-grey-3 q-pa-xl text-center text-h5 flex flex-center"
        style="height: 490px"
      >
        Não foram encontrados itens favoritos.
      </div>
    </template>

    <Footer
      :influ="getInfluData"
      :company="getCompanieDetails"
      :prodsByCat="getProductsByCategories"
      v-if="
        Object.keys(getInfluData).length &&
        Object.keys(getCompanieDetails).length
      "
    />
  </div>
</template>

<script setup>
import {
  setCustomLayoutLP,
  getCustomLayoutLP,
} from "@/shared/helpers/customLayoutHelper";
// import CarouselMultiItensComponent from "./components/Carousel.vue";
import InfluHeader from "./components/InfluHeader.vue";
import { baseURL } from "@/shared/http/api";
import axios from "axios";

import { useActions, useGetters } from "vuex-composition-helpers";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import Products from "./components/Products.vue";

const route = useRoute();
const in_request = ref(true);

const {
  getProductsByCategories,
  getInfluData,
  getCompanieDetails,
  getInfluEcomCustomization,
} = useGetters({
  getProductsByCategories: "public_landingpage/getProductsByCategories",
  getInfluData: "public_landingpage/getInfluData",
  getCompanieDetails: "public_landingpage/getCompanieDetails",
  getInfluEcomCustomization: "public_landingpage/getInfluEcomCustomization",
});

const {
  fetchInfluProductsByCategories,
  fetchInfluEcomCustomization,
  fetchCompanieDetails,
} = useActions({
  fetchInfluProductsByCategories:
    "public_landingpage/fetchInfluProductsByCategories",
  fetchInfluEcomCustomization: "public_landingpage/fetchInfluEcomCustomization",
  fetchCompanieDetails: "public_landingpage/fetchCompanieDetails",
});

const scrollTo = (title) => {
  document.getElementById(title).scrollIntoView({ behavior: "smooth" });
};

const layout = ref(getCustomLayoutLP("_LP"));

const fetchParam = (param) =>
  axios.get(
    `${baseURL}/api/v1/parameters/p/${route.params.childSlug}/${param}`
  );

onMounted(() => {
  const payload = {
    slug: route.params.childSlug,
    parentSlug: route.params.parentSlug,
  };

  Promise.all([
    fetchParam("custom_layout")
      .then(({ data }) => setCustomLayoutLP(data.value))
      .then(() => (layout.value = getCustomLayoutLP("_LP"))),
    fetchInfluProductsByCategories(payload),
    fetchInfluEcomCustomization(payload),
    fetchCompanieDetails({ slug: route.params.childSlug }),
  ]).finally(() => (in_request.value = false));
});
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
  padding: 0px;
  font-family: "Montserrat", sans-serif !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.container-categorys {
  margin-top: 2.5rem;
}

.section-category {
  margin-bottom: 3rem;
}

.category-title {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: clamp(22px, 4vw, 32px);
}

.category-title::before,
.category-title::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #dddcdc;
    margin: 0 15px;
}
</style>
