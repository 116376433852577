<template>
    <div class="products">
        <ProductCard 
          class="product" 
          v-for="( product, index ) in products" 
          :key="index" 
          :data="product" 
        />
    </div>
</template>
<script setup>
import { defineProps } from 'vue';
import ProductCard from './ProductCard.vue';

defineProps({
    products: Object,
})
</script>
<style scoped>
.products {
    display: flex;
    gap: 2rem;
    justify-content: start;
    overflow-x: auto;
    scrollbar-width: none;
    margin: 2rem 14px 0px 14px;
}

@media (max-width: 639px) {
  .products {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  }
}

@media ( min-width: 640px ) {
    .products {
        flex-wrap: wrap;
        justify-content: center;
        overflow-x: visible;
        margin: 1.5rem 0px 0px 0px;
    }
}
</style>