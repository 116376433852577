<template>
    <div class="header">
        <div class="header-inner">
            <div class="header-left">
                <q-img :src="companyDetails?.logo" class="logo" />
            </div>
            <div class="header-center">
                <q-form @submit.prevent="menuSearchResults.open()">
                    <q-input
                        ref="refSearchInput" 
                        outlined 
                        autocomplete="off"
                        @click="menuSearchResults.open()"
                        placeholder="O que você procura hoje?"
                        menu-anchor="none"
                        v-model="searchQuery"
                    >
                        <q-btn type="submit" flat>
                            <q-icon name="search" />
                        </q-btn>
                    </q-input>
                </q-form>
                <MenuSearchResults
                    v-if="showSearchResults"
                    :getAllProducts="getAllProducts" 
                    :searchQuery="searchQuery"
                    :closeSearchResults="menuSearchResults.close"
                />
            </div>
            <div class="header-right">
                <q-btn flat class="external-link" v-if="$q.screen.width > 599" @click="goToExternalStore">
                    Ir para {{ companyDetails?.name.split(',').slice(0, 2).join() }}
                </q-btn>

                <div v-else>
                    <q-btn flat color="black" icon="menu" @click="setSidebarStatus(true)" />
                </div>
            </div>
        </div>
    </div>
    <Tabs v-if="$q.screen.width > 900" :data="getProductsByCategories" :scrollTo="scrollTo" />

    <Sidebar 
        :data="getProductsByCategories" 
        :scrollTo="scrollTo" 
        :handleMenuClick="handleMenuClick"
    />
</template>
<script setup>
import { defineProps, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { useRoute } from "vue-router";

import Sidebar from './Sidebar.vue';
import Tabs from './Tabs.vue';
import MenuSearchResults from './MenuSearchResults.vue';

const props = defineProps({
    companyDetails: Object,
    getProductsByCategories: Object,
    scrollTo: Function,
    ecommCustomization: Object
})

const refSearchInput = ref(null)
const searchQuery = ref('')
const showSearchResults = ref(false);

const route = useRoute();

const { getAllProducts } = useGetters({
    getAllProducts: 'public_landingpage/getAllProducts'
})

const { setSidebarStatus } = useActions({
    setSidebarStatus: 'public_landingpage/setSidebarStatus'
})

const handleMenuClick = (title) => {
 setSidebarStatus(false);
 menuSearchResults.close()

  nextTick(() => {
    props.scrollTo(title);
  });
};

// Vai para a o site do parceiro
const goToExternalStore = () => {
    window.open(
        props.ecommCustomization?.ecomm_customization?.link_to_store,
        '_blank'
    )
}

const menuSearchResults = {
    open() {
        showSearchResults.value = true;
        bodyScroll.enabled();
    },

    close() {
        showSearchResults.value = false;
        bodyScroll.disabled();
    }
}

//Funções para desabilitar e habilitar o scroll do body
const bodyScroll = {
    enabled: () => {
        document.body.classList.add('no-scroll')
    },

    disabled: () => {
        document.body.classList.remove('no-scroll')
    }
}

// Caso o menu de produtos pesquisados esteja aberto, detecta quando 'ESC' é pressionado para fecha-lo
const handleEscapeKey = (event) => {
    if ( showSearchResults.value ) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            menuSearchResults.close()
        }
    }
}

// Define valores personalizados para as variaveis, quando o parceiro é FLIPNET
const setCSSVariables = () => {
    if ( route.params.childSlug === "flipnet" ) {
        const el = document.documentElement;

        el.style.setProperty('--width__logo-desktop', '75px');
        el.style.setProperty('--top__menu-lp-searchbar', '72px');
    }
}

onMounted(() => {
    document.addEventListener('keydown', handleEscapeKey)
    setCSSVariables()
})

onUnmounted(() => document.removeEventListener('keydown', handleEscapeKey))

</script>
<style scoped>
.header {
    padding: 0.7rem 0.5rem;
}

.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px
}

.header-center {
    flex: 1;
    display: flex;
    justify-content: center;
}

.header-right .external-link {
    border-radius: 6px;
    transition: background-color 250ms ease;
    text-decoration: underline;
}

.header-right .external-link:hover {
    background-color: #f4f4f4;
}

.header-right > div .q-btn {
    padding: 0px 0.1rem;
}

.q-form {
    width: 100%;
    max-width: 600px; 
}

.q-form :deep(i) {
    color: var(--primary-custom-color)
}

.q-form :deep(.q-field__control) {
    height: 45px;
}

:deep(.q-field--outlined .q-field__control) {
    background-color: #f4f4f4;
}

:deep(.q-field--outlined .q-field__control:before) {
    border: none;
}

.logo {
    width: var(--width__logo-mobile);
}

.external-link :deep(span) {
    text-transform: initial;
    font-size: clamp(12px, 3.5vw, 15px);
}

.external-link.text-primary {
    color: var(--primary-custom-color) !important;
}

@media ( max-width: 639px ) {
    :deep(.q-field .q-btn) {
        padding-right: 0px;
    }

    :deep(.btn-add-to-cart) {
        max-width: 42px;
        max-height: 25px;
    }

    :deep(.btn-add-to-cart .q-btn__content) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :deep(.btn-add-to-cart .q-icon) {
        font-size: 1.2rem;
    }
}

@media ( min-width: 640px ) {
    .header {
        padding: 1rem;
    }

    .header-right {
        display: block;
    }

    .logo {
        width: var(--width__logo-desktop);
    }
}
</style>
