<template>
  <q-card
    class="card"
    flat
    bordered
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="handleClick"
  >
  <div class="card-header">
    <q-img :src="data.full_url" loading="lazy" @error="imageError"/>
  </div>

    <q-card-section>
      <q-item-label caption class="text-grey" style="font-size: 8pt">
        {{ data.brand_name }}
      </q-item-label>
      <div class="q-mt-sm q-mb-xs card-title" v-html="data.name" />
      <div class="q-mb-sm price-container">
        <div v-if="data.sale_price">
          <span
            :class="
              data.price_ref > data.sale_price ? 'discount' : 'non-discount'
            "
            >{{ formatToRealBR(data.price_ref) }}</span
          ><br />
          <span class="current-price"
            ><b>{{ formatToRealBR(data.sale_price) }}</b></span
          >
        </div>

        <div v-else>
          <span :class="'non-discount'">{{
            formatToRealBR(data.price_ref)
          }}</span
          ><br />
          <span class="current-price"
            ><b>{{ formatToRealBR(data.price_ref) }}</b></span
          >
        </div>

        <q-btn
          flat
          v-if="hover || isMobile"
          :label="!isMobile ? $t('global.buy') : undefined"
          icon="shopping_cart"
          class="btn-add-to-cart"
          @click="handleClick"
          :style="`background-color: ${
            layout.body_background !== '#E0E0E0'
              ? layout.menu_top_background
              : '#000'
          };
            color: ${layout.menu_itens_text_color};
          `"
        />
      </div>

      <div
        class="text-caption text-grey text-justify description"
        v-html="data.short_description"
      />
    </q-card-section>
  </q-card>
</template>

<script setup>
import { defineProps, ref, onMounted, onUnmounted } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { getCustomLayoutLP } from "@/shared/helpers/customLayoutHelper";


const hover = ref(false);
const isMobile = ref(window.innerWidth <= 900);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const {
  getInfluEcomCustomization,
} = useGetters({
  getInfluEcomCustomization: "public_landingpage/getInfluEcomCustomization",
});

const layout = ref(getCustomLayoutLP("_LP"));

const handleClick = () => {
  const linkToStore = getInfluEcomCustomization.value?.ecomm_customization?.link_to_store;
  const url = new URL(props.data.original_link);

  if (linkToStore) {
    const linkToStoreParams = new URLSearchParams(new URL(linkToStore).search);

    // Adiciona cada parâmetro de linkToStore à URL original
    linkToStoreParams.forEach((value, key) => {
      url.searchParams.set(key, value);
    });
  }

  const finalUrl = url.toString();

  window.open(
    finalUrl,
    "_blank"
  );
}


// Função para detectar tamanho da tela
const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 900;
};

function formatToRealBR(value) {
  return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
}

const imageError = () => console.log('ImageError')
// Adicionar event listener para redimensionamento da tela
onMounted(() => {
  window.addEventListener("resize", updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateIsMobile);
});
</script>

<style lang="scss" scoped>
.card {
  flex: 0 0 auto;
  min-width: 220px;
  max-width: 220px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.card-title {
  font-size: clamp(13.8px, 4vw, 15px);
  font-weight: 500;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  height: calc(2 * 1.35em);
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 2rem;
  font-family: "Montserrat", sans-serif !important;
}

.card-header {
  background-color: #F2F2F2;
  height: 150px;
}

.card-header .q-img{
  max-height: 150px;
  max-width: 150px;
  margin: 0 auto;
}

.card-header .q-img :deep(img){
  object-position: bottom !important;
}


.q-img {
  display: flex;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-height: 1.4;
  -webkit-box-orient: vertical;
  font-family: "Montserrat", sans-serif !important;
  overflow: hidden;
}

.btn-add-to-cart {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.3s ease;

  @media (max-width: 900px) {
    position: relative;
    margin-left: auto;
    margin-right: 0.5rem;
    top: 0.1rem;
    width: 42px;
    opacity: 1;
    transition: none;
  }
}

.btn-add-to-cart :deep(.q-icon) {
  font-size: 1.5rem;
}

.card:hover .btn-add-to-cart {
  opacity: 1;

  @media (max-width: 900px) {
    opacity: 1;
  }
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  font-family: "Montserrat", sans-serif !important;

  @media (max-width: 900px) {
    justify-content: flex-start;
  }
}

.current-price {
  color: #3a3a3a;
  font-size: 16px;
}

.discount {
  text-decoration: line-through;
  font-size: clamp(10px, 3.5vw, 12px);
  color: #999;
}

.non-discount {
  opacity: 0;
  font-size: 10px;
}

@media ( min-width: 640px ) {

  .card {
    max-width: 270px;
    min-width: 270px;
  }

  .card-header {
     height: 200px
  }
  
  .card-header .q-img{
    max-height: 200px;
    max-width: 200px;
  }

  .card:hover {
    scale: 1.02;
    transition: 0.5s;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  }
  
  .btn-add-to-cart :deep(.q-icon) {
    font-size: 1.715em;
  }
}
</style>
